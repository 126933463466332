"use client";

import { useRouter, useSearchParams } from "next/navigation";

import queryStringify from "qs-stringify";

import { createAuthChallenge } from "@/utils/api/client";
import { ApiError } from "@/utils/api/fetch";
import { useForm } from "@/utils/useForm";
import { useRequest } from "@/utils/useRequest";

import { H2 } from "@/components/Headings";
import Message from "@/components/Message";
import { Button, Submit } from "@/components/forms/Button";
import Input from "@/components/forms/Input";

import {
  countryDetails,
  getPlaceholder,
} from "@/hl-common/types/CountryDetails";
import type { API } from "@/hl-common/types/api/API";
import type { CountryCode } from "@/hl-common/types/api/CountryCode";
import { auth_channel } from "@/hl-common/types/api/PrismaEnums";
import { CountrySelect } from "./CountrySelect";

const LoginForm = ({
  defaultCountryCode,
}: {
  defaultCountryCode: CountryCode;
}) => {
  const params = useSearchParams();
  const redirectUrl = params.get("redirectUrl") || "/courses";

  const { push } = useRouter();

  const { loading, error, run } = useRequest(createAuthChallenge);

  const { fields, handleInputChange, handleSubmit, setFields } =
    useForm<API.createAuthChallenge.body>(
      {
        countryCode: defaultCountryCode,
        authChannel: auth_channel.WHATSAPP,
        phone: "",
      },
      (body) => {
        run(
          createAuthChallenge({ body }),
          ({ data: { formattedPhone } }) => {
            const query = queryStringify({
              phone: formattedPhone,
              redirectUrl,
              authChannel: body.authChannel,
            });
            push(`/otp?${query}`);

            return true;
          },
          (error: unknown) => {
            if (error instanceof ApiError && error.statusCode === 409) {
              // already logged in - go to redirectUrl
              push(redirectUrl);
            }
          },
        );
      },
    );

  const { authChannel, countryCode } = fields;

  const canSwitchToSms =
    authChannel === auth_channel.WHATSAPP &&
    countryDetails[countryCode].smsEnabled;

  const canSwitchToWhatsApp = authChannel === auth_channel.SMS;

  return (
    <div className="text-center mx-auto">
      <H2 className="mb-8">
        Log In <span className="font-normal">/</span> Register
      </H2>
      <form name="login" onSubmit={handleSubmit}>
        <CountrySelect
          name="countryCode"
          value={fields.countryCode}
          authChannel={authChannel}
          handleInputChange={handleInputChange}
        />

        <Input
          required
          autoFocus
          className="mx-auto text-center"
          type="tel"
          name="phone"
          value={fields.phone}
          label={
            authChannel === auth_channel.SMS
              ? "Phone Number"
              : "WhatsApp Number"
          }
          placeholder={getPlaceholder(fields.countryCode)}
          onChange={handleInputChange}
        />

        {error && <Message error>{error}</Message>}

        <Submit className="mb-4" loading={loading} />

        {canSwitchToSms && (
          <Button
            className="mb-4"
            minimal
            onClick={(e) => {
              e.preventDefault();
              setFields({
                ...fields,
                authChannel: auth_channel.SMS,
              });
            }}
          >
            Switch to SMS
          </Button>
        )}

        {canSwitchToWhatsApp && (
          <Button
            className="mb-4"
            minimal
            onClick={(e) => {
              e.preventDefault();
              setFields({
                ...fields,
                authChannel: auth_channel.WHATSAPP,
              });
            }}
          >
            Switch to WhatsApp
          </Button>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
