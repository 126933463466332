// we put the routes into a separate file from API req/resp stuff so that we can
// import them on the front-end without putting class-validator and other
// dependencies in the final front-end bundle

// assert-matching.ts helps keep these two definitions in parallel

export type Req = {
  method: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
  canRetry?: boolean;
};

export namespace Routes {
  export const getCourses: Req = {
    method: "GET",
    path: "/courses",
  };

  export const getCourse: Req = {
    method: "GET",
    path: "/courses/:courseId",
  };

  export const getCourseFull: Req = {
    method: "GET",
    path: "/courses/:courseId/full",
  };

  export const updateCourse: Req = {
    method: "PUT",
    path: "/courses/:courseId",
  };

  export const updateCourseNotificationSetting: Req = {
    method: "PUT",
    path: "/courses/:courseId/notification-settings",
  };

  export const getCourseLandingPages: Req = {
    method: "GET",
    path: "/courses/:courseId/landing-pages",
  };

  export const createLandingPage: Req = {
    method: "POST",
    path: "/landing-pages",
  };

  export const updateLandingPage: Req = {
    method: "PUT",
    path: "/landing-pages/:landingPageId",
  };

  export const getMessages: Req = {
    method: "GET",
    path: "/messages",
  };

  export const sendMessage: Req = {
    method: "POST",
    path: "/messages",
  };

  export const getNameChangeRequests: Req = {
    method: "GET",
    path: "/name-change-requests",
  };

  export const getTags: Req = {
    method: "GET",
    path: "/tags",
  };

  export const getUsers: Req = {
    method: "GET",
    path: "/users",
  };

  export const getUser: Req = {
    method: "GET",
    path: "/users/:userId",
  };

  export const updateUser: Req = {
    method: "PUT",
    path: "/users/:userId",
  };

  export const updateUserPassword: Req = {
    method: "PUT",
    path: "/users/:userId/password",
  };

  export const updateUserRole: Req = {
    method: "PUT",
    path: "/users/:userId/role",
  };

  export const getUserEvents: Req = {
    method: "GET",
    path: "/users/:userId/events",
  };

  export const createEvent: Req = {
    method: "POST",
    path: "/events/admin",
  };

  export const deleteEventsForUserByCourse: Req = {
    method: "DELETE",
    path: "/users/:userId/events/by-course/:courseId",
  };

  export const createEventMeta: Req = {
    method: "POST",
    path: "/events/:eventId/meta",
  };

  export const updateEventMeta: Req = {
    method: "PUT",
    path: "/events/:eventId/meta",
  };

  export const setDefaultLandingPage: Req = {
    method: "PUT",
    path: "/courses/:courseId/default-landing-page",
  };

  export const getUserCourses: Req = {
    method: "GET",
    path: "/users/:userId/courses",
  };

  export const getUserGroups: Req = {
    method: "GET",
    path: "/users/:userId/groups",
  };

  export const addUserToGroup: Req = {
    method: "POST",
    path: "/users/:userId/groups",
  };

  export const removeUserFromGroup: Req = {
    method: "DELETE",
    path: "/users/:userId/groups/:groupId",
  };

  export const getGroups: Req = {
    method: "GET",
    path: "/groups",
  };

  export const getUserMessages: Req = {
    method: "GET",
    path: "/users/:userId/messages",
  };

  export const getUserTags: Req = {
    method: "GET",
    path: "/users/:userId/tags",
  };

  export const updateNameChangeRequest: Req = {
    method: "PUT",
    path: "/name-change-requests/:nameChangeRequestId",
  };

  export const getTagsSelect: Req = {
    method: "GET",
    path: "/tags",
  };

  export const getActiveUsers: Req = {
    method: "GET",
    path: "/stats/active-users",
  };

  export const getLoginConversions: Req = {
    method: "GET",
    path: "/stats/login-conversions",
  };

  export const getPlatformConversions: Req = {
    method: "GET",
    path: "/stats/platform-conversions",
  };

  export const getCourseConversions: Req = {
    method: "GET",
    path: "/stats/course-conversions/:courseId",
  };

  export const getModuleConversions: Req = {
    method: "GET",
    path: "/stats/module-conversions/:moduleId",
  };

  export const getDuplicateCourseCompleteEvents: Req = {
    method: "GET",
    path: "/events/duplicate-course-complete",
  };

  export const getMissingCourseCompleteEvents: Req = {
    method: "GET",
    path: "/events/missing-course-complete",
  };

  export const getMissingModuleCompleteEvents: Req = {
    method: "GET",
    path: "/events/missing-module-complete",
  };

  export const getFile: Req = {
    method: "GET",
    path: "/files/:fileId",
  };

  export const me: Req = {
    method: "GET",
    path: "/users/me",
  };

  export const createAuthChallenge: Req = {
    method: "POST",
    path: "/auth-challenge/create",
  };

  export const verifyOtp: Req = {
    method: "POST",
    path: "/auth-challenge/verify-otp",
  };

  export const verifyPassword: Req = {
    method: "POST",
    path: "/auth-challenge/verify-password",
  };

  export const logout: Req = {
    method: "POST",
    path: "/logout",
  };

  export const registerUser: Req = {
    method: "PUT",
    path: "/users/register",
  };

  export const updateUserEmail: Req = {
    method: "PUT",
    path: "/users/email",
  };

  export const updateUserName: Req = {
    method: "PUT",
    path: "/users/name",
  };

  export const getUserNotificationSettings: Req = {
    method: "GET",
    path: "/users/:userId/notification-settings",
  };

  export const getUserNameChangeRequests: Req = {
    method: "GET",
    path: "/users/:userId/name-change-requests",
  };

  export const updateUserNotificationSetting: Req = {
    method: "PUT",
    path: "/users/:userId/notification-settings",
  };

  export const getCoursesWithStatus: Req = {
    method: "GET",
    path: "/courses-with-status",
  };

  export const getCourseWithStatus: Req = {
    method: "GET",
    path: "/courses-with-status/:courseId",
  };

  export const getLandingPage: Req = {
    method: "GET",
    path: "/landing-pages/:slug",
  };

  export const getModule: Req = {
    method: "GET",
    path: "/modules/:moduleId",
  };

  export const getUserEventsByCourse: Req = {
    method: "GET",
    path: "/events/by-course/:courseId",
  };

  export const getCompletedCardsByModule: Req = {
    method: "GET",
    path: "/events/completed-cards-by-module/:moduleId",
  };

  export const ingestEvent: Req = {
    method: "POST",
    path: "/events",
  };

  export const verifyCourseComplete: Req = {
    method: "GET",
    path: "/events/verify-course-complete/:eventId/:verification",
  };

  export const getUserEventsCourseComplete: Req = {
    method: "GET",
    path: "/events/course-complete",
  };

  export const syncTags: Req = {
    method: "POST",
    path: "/sync-tags",
  };

  export const getCertificatePdf: Req = {
    method: "GET",
    path: "/certificates/:uuid.pdf",
  };
}
